export const sortingButtons = [
  {
    tag: "all",
    label: "Mostra tutti",
    backgroundColor: "#105689",
  },
  {
    tag: "rubinetteria",
    label: "rubinetteria",
    backgroundColor: "#986562",
  },
  {
    tag: "sanitari",
    label: "sanitari",
    backgroundColor: "#E2D4CB",
    textColor: "#86756A",
  },
  {
    tag: "arredobagno",
    label: "arredobagno",
    backgroundColor: "#758177",
  },
  {
    tag: "boxdoccia",
    label: "box doccia",
    backgroundColor: "#5A7D8B",
  },
  {
    tag: "piattidoccia",
    label: "piatti doccia",
    backgroundColor: "#C7C3C0",
    textColor: "#636262",
  },
  {
    tag: "radiatori",
    label: "radiatori",
    backgroundColor: "#8C9898",
  },
  {
    tag: "piastrelle",
    label: "piastrelle",
    backgroundColor: "#B89086",
  },
  {
    tag: "parquet",
    label: "parquet",
    backgroundColor: "#C6A47E",
  },
  {
    tag: "cucine",
    label: "cucine",
    backgroundColor: "#A8BD9B",
    textColor: "#536647",
  },
  {
    tag: "soggiorni",
    label: "soggiorni",
    backgroundColor: "#52625F",
  },
  {
    tag: "tavoli",
    label: "tavoli",
    backgroundColor: "#959796",
  },
  {
    tag: "sedie",
    label: "sedie",
    backgroundColor: "#AC968B",
  },
  {
    tag: "divani",
    label: "divani",
    backgroundColor: "#897C74",
  },
  {
    tag: "letti",
    label: "letti",
    backgroundColor: "#A2725E",
  },
];

export const images = [
  {
    source: "/img/001-rubinetteria/rubinetteria-1.jpg",
    tag: "rubinetteria",
    info: "interior design",
  },
  {
    source: "/img/002-sanitari/sanitari-1.jpg",
    tag: "sanitari",
    info: "interior design",
  },
  {
    source: "/img/003-arredobagno/arredobagno-1.jpg",
    tag: "arredobagno",
    info: "interior design",
  },
  {
    source: "/img/004-box-doccia/box-doccia-1.jpg",
    tag: "boxdoccia",
    info: "interior design",
  },
  {
    source: "/img/005-piatti-doccia/piatti-doccia-1.jpg",
    tag: "piattidoccia",
    info: "interior design",
  },
  {
    source: "/img/006-radiatori/radiatori-1.jpg",
    tag: "radiatori",
    info: "interior design",
  },
  {
    source: "/img/007-piastrelle/piastrelle-1.jpg",
    tag: "piastrelle",
    info: "interior design",
  },
  {
    source: "/img/008-parquet/parquet-1.jpg",
    tag: "parquet",
    info: "interior design",
  },
  {
    source: "/img/009-cucine/cucine-1.jpg",
    tag: "cucine",
    info: "interior design",
  },
  {
    source: "/img/010-soggiorni/soggiorni-1.jpg",
    tag: "soggiorni",
    info: "interior design",
  },
  {
    source: "/img/011-tavoli/tavoli-1.jpg",
    tag: "tavoli",
    info: "interior design",
  },
  {
    source: "/img/012-sedie/sedie-1.jpg",
    tag: "sedie",
    info: "interior design",
  },
  {
    source: "/img/013-divani/divani-1.jpg",
    tag: "divani",
    info: "interior design",
  },
  {
    source: "/img/014-letti/letti-1.jpg",
    tag: "letti",
    info: "interior design",
  },
  {
    source: "/img/001-rubinetteria/rubinetteria-5.jpg",
    tag: "rubinetteria",
    info: "interior design",
  },
  {
    source: "/img/002-sanitari/sanitari-2.jpg",
    tag: "sanitari",
    info: "interior design",
  },
  {
    source: "/img/003-arredobagno/arredobagno-2.jpg",
    tag: "arredobagno",
    info: "interior design",
  },
  {
    source: "/img/004-box-doccia/box-doccia-2.jpg",
    tag: "boxdoccia",
    info: "interior design",
  },
  {
    source: "/img/005-piatti-doccia/piatti-doccia-2.jpg",
    tag: "piattidoccia",
    info: "interior design",
  },
  {
    source: "/img/006-radiatori/radiatori-2.jpg",
    tag: "radiatori",
    info: "interior design",
  },
  {
    source: "/img/007-piastrelle/piastrelle-2.jpg",
    tag: "piastrelle",
    info: "interior design",
  },
  {
    source: "/img/008-parquet/parquet-2.jpg",
    tag: "parquet",
    info: "interior design",
  },
  {
    source: "/img/009-cucine/cucine-2.jpg",
    tag: "cucine",
    info: "interior design",
  },
  {
    source: "/img/010-soggiorni/soggiorni-2.jpg",
    tag: "soggiorni",
    info: "interior design",
  },
  {
    source: "/img/011-tavoli/tavoli-2.jpg",
    tag: "tavoli",
    info: "interior design",
  },
  {
    source: "/img/012-sedie/sedie-2.jpg",
    tag: "sedie",
    info: "interior design",
  },
  {
    source: "/img/001-rubinetteria/rubinetteria-3.jpg",
    tag: "rubinetteria",
    info: "interior design",
  },
  {
    source: "/img/001-rubinetteria/rubinetteria-6.jpg",
    tag: "rubinetteria",
    info: "interior design",
  },
  {
    source: "/img/002-sanitari/sanitari-3.jpg",
    tag: "sanitari",
    info: "interior design",
  },
  {
    source: "/img/003-arredobagno/arredobagno-3.jpg",
    tag: "arredobagno",
    info: "interior design",
  },
  {
    source: "/img/004-box-doccia/box-doccia-3.jpg",
    tag: "boxdoccia",
    info: "interior design",
  },
  {
    source: "/img/005-piatti-doccia/piatti-doccia-3.jpg",
    tag: "piattidoccia",
    info: "interior design",
  },
  {
    source: "/img/006-radiatori/radiatori-3.jpg",
    tag: "radiatori",
    info: "interior design",
  },
  {
    source: "/img/007-piastrelle/piastrelle-3.jpg",
    tag: "piastrelle",
    info: "interior design",
  },
  {
    source: "/img/008-parquet/parquet-3.jpg",
    tag: "parquet",
    info: "interior design",
  },
  {
    source: "/img/008-parquet/parquet-4.jpg",
    tag: "parquet",
    info: "interior design",
  },
  {
    source: "/img/009-cucine/cucine-3.jpg",
    tag: "cucine",
    info: "interior design",
  },
  {
    source: "/img/010-soggiorni/soggiorni-3.jpg",
    tag: "soggiorni",
    info: "interior design",
  },
  {
    source: "/img/011-tavoli/tavoli-3.jpg",
    tag: "tavoli",
    info: "interior design",
  },
  {
    source: "/img/012-sedie/sedie-3.jpg",
    tag: "sedie",
    info: "interior design",
  },
  {
    source: "/img/013-divani/divani-2.jpg",
    tag: "divani",
    info: "interior design",
  },

  {
    source: "/img/014-letti/letti-2.jpg",
    tag: "letti",
    info: "interior design",
  },
  {
    source: "/img/001-rubinetteria/rubinetteria-4.jpg",
    tag: "rubinetteria",
    info: "interior design",
  },
  {
    source: "/img/001-rubinetteria/rubinetteria-2.jpg",
    tag: "rubinetteria",
    info: "interior design",
  },
  {
    source: "/img/002-sanitari/sanitari-4.jpg",
    tag: "sanitari",
    info: "interior design",
  },

  {
    source: "/img/003-arredobagno/arredobagno-4.jpg",
    tag: "arredobagno",
    info: "interior design",
  },
  {
    source: "/img/003-arredobagno/arredobagno-5.jpg",
    tag: "arredobagno",
    info: "interior design",
  },

  {
    source: "/img/007-piastrelle/piastrelle-4.jpg",
    tag: "piastrelle",
    info: "interior design",
  },
  {
    source: "/img/008-parquet/parquet-5.jpg",
    tag: "parquet",
    info: "interior design",
  },
  {
    source: "/img/008-parquet/parquet-6.jpg",
    tag: "parquet",
    info: "interior design",
  },
];

/* export const images = [
    {
        source: '/img/001-rubinetteria/rubinetteria-1.jpg',
        tag: 'rubinetteria',
        info: 'interior design'
    },
    {
        source: '/img/001-rubinetteria/rubinetteria-2.jpg',
        tag: 'rubinetteria',
        info: 'interior design'
    },
    {
        source: '/img/001-rubinetteria/rubinetteria-3.jpg',
        tag: 'rubinetteria',
        info: 'interior design'
    },
    {
        source: '/img/001-rubinetteria/rubinetteria-4.jpg',
        tag: 'rubinetteria',
        info: 'interior design'
    },
    {
        source: '/img/001-rubinetteria/rubinetteria-5.jpg',
        tag: 'rubinetteria',
        info: 'interior design'
    },
    {
        source: '/img/001-rubinetteria/rubinetteria-6.jpg',
        tag: 'rubinetteria',
        info: 'interior design'
    },
    {
        source: '/img/002-sanitari/sanitari-1.jpg',
        tag: 'sanitari',
        info: 'interior design'
    },
    {
        source: '/img/002-sanitari/sanitari-2.jpg',
        tag: 'sanitari',
        info: 'interior design'
    },
    {
        source: '/img/002-sanitari/sanitari-3.jpg',
        tag: 'sanitari',
        info: 'interior design'
    },
    {
        source: '/img/002-sanitari/sanitari-4.jpg',
        tag: 'sanitari',
        info: 'interior design'
    },
    {
        source: '/img/003-arredobagno/arredobagno-1.jpg',
        tag: 'arredobagno',
        info: 'interior design'
    },
    {
        source: '/img/003-arredobagno/arredobagno-2.jpg',
        tag: 'arredobagno',
        info: 'interior design'
    },
    {
        source: '/img/003-arredobagno/arredobagno-3.jpg',
        tag: 'arredobagno',
        info: 'interior design'
    },
    {
        source: '/img/003-arredobagno/arredobagno-4.jpg',
        tag: 'arredobagno',
        info: 'interior design'
    },
    {
        source: '/img/003-arredobagno/arredobagno-5.jpg',
        tag: 'arredobagno',
        info: 'interior design'
    },
    {
        source: '/img/004-box-doccia/box-doccia-1.jpg',
        tag: 'boxdoccia',
        info: 'interior design'
    },
    {
        source: '/img/004-box-doccia/box-doccia-2.jpg',
        tag: 'boxdoccia',
        info: 'interior design'
    },
    {
        source: '/img/004-box-doccia/box-doccia-3.jpg',
        tag: 'boxdoccia',
        info: 'interior design'
    },
    {
        source: '/img/005-piatti-doccia/piatti-doccia-1.jpg',
        tag: 'piattidoccia',
        info: 'interior design'
    },
    {
        source: '/img/005-piatti-doccia/piatti-doccia-2.jpg',
        tag: 'piattidoccia',
        info: 'interior design'
    },
    {
        source: '/img/005-piatti-doccia/piatti-doccia-3.jpg',
        tag: 'piattidoccia',
        info: 'interior design'
    },
    {
        source: '/img/006-radiatori/radiatori-1.jpg',
        tag: 'radiatori',
        info: 'interior design'
    },
    {
        source: '/img/006-radiatori/radiatori-2.jpg',
        tag: 'radiatori',
        info: 'interior design'
    },
    {
        source: '/img/006-radiatori/radiatori-3.jpg',
        tag: 'radiatori',
        info: 'interior design'
    },
    {
        source: '/img/007-piastrelle/piastrelle-1.jpg',
        tag: 'piastrelle',
        info: 'interior design'
    },
    {
        source: '/img/007-piastrelle/piastrelle-2.jpg',
        tag: 'piastrelle',
        info: 'interior design'
    },
    {
        source: '/img/007-piastrelle/piastrelle-3.jpg',
        tag: 'piastrelle',
        info: 'interior design'
    },
    {
        source: '/img/007-piastrelle/piastrelle-4.jpg',
        tag: 'piastrelle',
        info: 'interior design'
    },
    {
        source: '/img/008-parquet/parquet-1.jpg',
        tag: 'parquet',
        info: 'interior design'
    },
    {
        source: '/img/008-parquet/parquet-2.jpg',
        tag: 'parquet',
        info: 'interior design'
    },
    {
        source: '/img/008-parquet/parquet-3.jpg',
        tag: 'parquet',
        info: 'interior design'
    },
    {
        source: '/img/008-parquet/parquet-4.jpg',
        tag: 'parquet',
        info: 'interior design'
    },
    {
        source: '/img/008-parquet/parquet-5.jpg',
        tag: 'parquet',
        info: 'interior design'
    },
    {
        source: '/img/008-parquet/parquet-6.jpg',
        tag: 'parquet',
        info: 'interior design'
    },
    {
        source: '/img/009-cucine/cucine-1.jpg',
        tag: 'cucine',
        info: 'interior design'
    },
    {
        source: '/img/009-cucine/cucine-2.jpg',
        tag: 'cucine',
        info: 'interior design'
    },
    {
        source: '/img/009-cucine/cucine-3.jpg',
        tag: 'cucine',
        info: 'interior design'
    },
    {
        source: '/img/010-soggiorni/soggiorni-1.jpg',
        tag: 'soggiorni',
        info: 'interior design'
    },
    {
        source: '/img/010-soggiorni/soggiorni-2.jpg',
        tag: 'soggiorni',
        info: 'interior design'
    },
    {
        source: '/img/010-soggiorni/soggiorni-3.jpg',
        tag: 'soggiorni',
        info: 'interior design'
    },
    {
        source: '/img/011-tavoli/tavoli-1.jpg',
        tag: 'tavoli',
        info: 'interior design'
    },
    {
        source: '/img/011-tavoli/tavoli-2.jpg',
        tag: 'tavoli',
        info: 'interior design'
    },
    {
        source: '/img/011-tavoli/tavoli-3.jpg',
        tag: 'tavoli',
        info: 'interior design'
    },
    {
        source: '/img/012-sedie/sedie-1.jpg',
        tag: 'sedie',
        info: 'interior design'
    },
    {
        source: '/img/012-sedie/sedie-2.jpg',
        tag: 'sedie',
        info: 'interior design'
    },
    {
        source: '/img/012-sedie/sedie-3.jpg',
        tag: 'sedie',
        info: 'interior design'
    },
    {
        source: '/img/013-divani/divani-1.jpg',
        tag: 'divani',
        info: 'interior design'
    },
    {
        source: '/img/013-divani/divani-2.jpg',
        tag: 'divani',
        info: 'interior design'
    },
    {
        source: '/img/014-letti/letti-1.jpg',
        tag: 'letti',
        info: 'interior design'
    },
    {
        source: '/img/014-letti/letti-2.jpg',
        tag: 'letti',
        info: 'interior design'
    },
] */
